<h2 class="font-poppins">
    {{ globalJSData.isGerman ? german.ask : english.ask }}
</h2>
<span class="font-poppins">
    @if (globalJSData.isGerman) {
        Kontaktieren Sie mich über dieses Formular. Ich würde mich freuen, von Ihnen zu hören
        und mehr über Ihre Ideen zu erfahren, um diese mit meinen Fähigkeiten tatkräftig zu unterstützen.
    } @else {
        Contact me using this form. I would be happy to hear from you
        and learn more about your ideas in order to actively support them with my skills.
    }
</span>
<span class="font-poppins">
    @if (globalJSData.isGerman) {
        Suchen Sie einen Frontend Developer? <strong>Kontaktieren Sie mich!</strong>
    } @else {
        Are you looking for a Frontend Developer? <strong>Contact Me!</strong>
    }
</span>