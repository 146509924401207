<form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
    <div class="contain1">
        <div class="input-contain" id="name-border" [ngClass]="{'redBorder': !name.valid && name.touched, 'greenBorder': name.valid}">
            <input #name="ngModel" [(ngModel)]="contactData.name" type="text" class="font-poppins" name="name"
                placeholder="{{ globalJSData.isGerman ? german.name : english.name }}" required minlength="4">
            <div class="img-contain">
                <img [src]="!name.valid && name.touched ? 'assets/img/icons/attention.png' : name.valid ? 'assets/img/icons/ok.png' : ''">
            </div>
        </div>
        <div class="request-contain">
            @if (!name.valid && name.touched) {
                <span class="font-poppins request-area" id="request-name">{{ globalJSData.isGerman ? german.request1 : english.request1 }}</span>
            }
        </div>
    </div>
    <div class="contain1">
        <div class="input-contain" id="mail-border" [ngClass]="{'redBorder': !mail.valid && mail.touched, 'greenBorder': mail.valid}">
            <input #mail="ngModel" [(ngModel)]="contactData.mail" type="email" class="font-poppins" name="mail" 
                placeholder="{{ globalJSData.isGerman ? german.mail : english.mail }}" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}">
            <div class="img-contain">
                <img [src]="!mail.valid && mail.touched ? 'assets/img/icons/attention.png' : mail.valid ? 'assets/img/icons/ok.png' : ''">    
            </div>
        </div>
        <div class="request-contain">
            @if (!mail.valid && mail.touched) {
                <span class="font-poppins request-area" id="request-mail">{{ globalJSData.isGerman ? german.request2 : english.request2 }}</span>
            }
        </div>
    </div>
    <div class="contain1">
        <div class="input-contain" id="message-border" [ngClass]="{'redBorder': !message.valid && message.touched, 'greenBorder': message.valid}">
            <textarea #message="ngModel" [(ngModel)]="contactData.message" class="font-poppins" name="message"
                placeholder="{{ globalJSData.isGerman ? german.message : english.message }}" cols="300" rows="100" required minlength="10"></textarea>
            <div class="img-contain">
                <img [src]="!message.valid && message.touched ? 'assets/img/icons/attention.png' : message.valid ? 'assets/img/icons/ok.png' : ''">
            </div>
        </div>
        <div class="request-contain">
            @if (!message.valid && message.touched) {
                <span class="font-poppins request-area" id="request-message">{{ globalJSData.isGerman ? german.request3 : english.request3 }}</span>
            }
        </div>
    </div>
    <div class="contain1">
        <div class="checkbox-contain">
            <div class="checkbox" id="checkbox-border" (click)="PPaccept = !PPaccept" [ngClass]="{'redBorder': formValid == false, 'greenBorder': PPaccept}">
                <img [src]="PPaccept ? 'assets/img/icons/check-mark-green.png' : ''" class="check">
            </div>
            <span class="font-poppins pp-text">{{ globalJSData.isGerman ? german.text1 : english. text1 }}</span>
            <span [routerLink]="globalJSData.isGerman ? '/datenschutzrichtlinien' : '/privacyPolicy'" (click)="globalJSData.mainPage(false)"
                class="font-poppins pp-link">{{ globalJSData.isGerman ? german.link : english. link }}</span>
            <span class="font-poppins pp-text">{{ globalJSData.isGerman ? german.text2 : english. text2 }}</span>
        </div>
        <div class="request-contain">
            <span class="font-poppins request-area" id="request-pp">
                {{ globalJSData.isGerman ? ((formValid == false && !PPaccept) ? german.request4 : '') : ((formValid == false && !PPaccept) ? english.request4 : '') }}
            </span>
        </div>
    </div>
    <div class="contain1">
        <button class="font-poppins" [ngClass]="{'ready-bg': contactForm.valid && PPaccept}" type="submit" value="Submit">
            {{ globalJSData.isGerman ? german.button : english.button }}
        </button>
    </div>
</form>