<div class="master-contain">
    <app-profil class="main"></app-profil>
    <app-about-me class="main" id="aboutMe"></app-about-me>
    <app-my-skills class="main" id="mySkills"></app-my-skills>
    <app-portfolio class="main" id="portfolio"></app-portfolio>
    <app-contact class="main" id="contact"></app-contact>
    <div>
        <img src="assets/img/background/bg-shadow6.png" class="shadow">
        <div class="contain1">
            <div class="btn-contain" (click)="globalJSData.scrollToStart()">
                <img src="assets/img/icons/go-up.png" class="go-up-btn">
                <img src="assets/img/icons/go-up-turquoise.png" class="go-up-btn-tq">
            </div>
        </div>
    </div>
</div>