<div>
    <img src="assets/img/background/bg-shadow3edge.png" class="shadow-r">
    <img src="assets/img/background/bg-shadow4edge.png" class="shadow-l">
    <div class="contain1">
        <h1>{{ globalJSData.isGerman ? german.headline : english.headline }}</h1>

        <h2>{{ globalJSData.isGerman ? german.adress : english.adress }}</h2>
        <p>Sebastian Rieder<br />
            Siedlung 37<br />
            01744 Dippoldiswalde</p>

        <h2>{{ globalJSData.isGerman ? german.contact.headline : english.contact.headline }}</h2>
        <p>{{ globalJSData.isGerman ? german.contact.z1 : english.contact.z1 }}: +49 162 6923670<br />
            E-Mail: my.portfolio&#64;rieder-sebastian.de</p>

        <h2>{{ globalJSData.isGerman ? german.legal.headline : english.legal.headline }}</h2>
        <p>{{ globalJSData.isGerman ? german.legal.z1 : english.legal.z1 }}<br />
            Sebastian Rieder</p>

        <h2>{{ globalJSData.isGerman ? german.sources.headline : english.sources.headline }}</h2>
        <div class="contain1">{{ globalJSData.isGerman ? german.sources.z1 : english.sources.z1 }} 
            <div class="contain2"><div class="contain3"><a href="https://www.freepik.com" title="Freepik">Freepik</a></div> <span>from</span> <div class="contain4"><a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div></div>
            <div class="contain2"><div class="contain3"><a href="https://www.flaticon.com/authors/verluk" title="verluk">verluk</a></div> <span>from</span> <div class="contain4"><a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div></div>
            <div class="contain2"><div class="contain3"><a href="https://www.flaticon.com/authors/awicon" title="Awicon">Awicon</a></div> <span>from</span> <div class="contain4"><a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div></div>
            <div class="contain2"><div class="contain3"><a href="https://www.flaticon.com/authors/creatype" title="Creatype">Creatype</a></div> <span>from</span> <div class="contain4"><a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div></div>
            <div class="contain2"><div class="contain3"><a href="https://www.flaticon.com/authors/md-tanvirul-haque" title="Tanvirul">Tanvirul</a></div> <span>from</span> <div class="contain4"><a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div></div>
        </div>
    </div>
</div>