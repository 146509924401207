<div class="contain1">
    <div class="contain2">
        <h1 class="font-poppins">
            {{ globalJSData.isGerman ? german.headline : english.headline }}
        </h1>
    </div>
    <div class="contain2">
        <span class="font-poppins text">
            @if (globalJSData.isGerman) {
            Ich bin Sebastian Rieder, geboren 1986 in Wien. Ich begann meine berufliche Laufbahn in der Gastronomie, wo
            ich als gelernter Koch und Restaurantfachmann tätig war, zuletzt in leitender Position.
            Seit Juni 2022 lebe ich in Dippoldiswalde, Sachsen, und arbeite als Beikoch. Parallel dazu absolviere ich
            seit Jänner 2023 ein Bootcamp der Developer Akademie, um meine Programmierfähigkeiten zu verbessern.
            } @else {
            I am Sebastian Rieder, born in Vienna in 1986. I began my professional career in the gastronomy, where I
            worked as a trained chef and restaurant specialist, most recently in a managerial position.
            I have been living in Dippoldiswalde, Sachsen, since June 2022 and working as an assistant chef. At the same
            time, I have been completing an advanced training by the Developer Akademie since January 2023 to improve my
            programming skills.
            }
        </span>
    </div>
    <div class="contain2">
        <div class="img-contain">
            <img src="assets/img/icons/path.png" class="img1">
        </div>
        <div class="contain3">
            <span class="font-poppins text">
                @if (globalJSData.isGerman) {
                Früh entdeckte ich meine Leidenschaft für Technik, inspiriert durch meinen Vater, der als Drucker
                arbeitete.
                Obwohl ich mich zunächst für ein Studium als Programmierer interessierte, führte mich mein Bildungsweg
                zunächst in die Gastronomie.
                Dort lernte ich nicht nur die Feinheiten des Gastgewerbes kennen, sondern auch meine Lebensgefährtin,
                mit der ich seit fast einem Jahrzehnt zusammenlebe.
                } @else {
                I discovered my passion for technology early on, inspired by my father, who worked as a printer.
                Although I was initially interested in studying as a programmer, my educational path initially led me to
                the gastronomy.
                There I not only got to know the intricacies of the hospitality industry, but also my partner, with whom
                I have lived for almost a decade.
                }
            </span>
        </div>
    </div>
    <div class="contain2">
        <div class="img-contain">
            <img src="assets/img/icons/puzzle.png" class="img2">
        </div>
        <div class="contain3">
            <span class="font-poppins text">
                @if (globalJSData.isGerman) {
                Die Programmierung verkörpert grenzenlose Freiheit, während sich die Horizonte unserer Vorstellungskraft
                ausdehnen.
                Es ist die Kunstformung unserer technologischen Zukunft, sei es durch Innovation, Spielzeug für Kinder
                oder autonome Fahrsysteme.
                } @else {
                Programming embodies boundless freedom as the horizons of our imagination expand.
                It is the art shaping our technological future, whether through innovation, children's toys, or
                autonomous driving systems.
                }
            </span>
        </div>
    </div>
</div>