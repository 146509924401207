<div id="top">
    <img src="assets/img/background/bg-shadow5edge.png" class="shadow-r">
    <img src="assets/img/background/bg-shadow6edge.png" class="shadow-l">
    <div class="contain1">
        <h1 class="adsimple-312757335">Datenschutzerklärung</h1>
        <h2>Inhaltsverzeichnis</h2>
        <ul>
            <li>
                <a href="datenschutzrichtlinien#einleitung-ueberblick">Einleitung und Überblick</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#anwendungsbereich">Anwendungsbereich</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#rechtsgrundlagen">Rechtsgrundlagen</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#speicherdauer">Speicherdauer</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#sicherheit-datenverarbeitung">Sicherheit der Datenverarbeitung</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#kommunikation">Kommunikation</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#auftragsverarbeitungsvertrag-avv">Auftragsverarbeitungsvertrag (AVV)</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#cookies">Cookies</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#kundendaten">Kundendaten</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#social-media-einleitung">Social Media Einleitung</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#externe-onlineplattformen-einleitung">Externe Onlineplattformen Einleitung</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#audio-video-einleitung">Audio &amp; Video Einleitung</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#webdesign-einleitung">Webdesign Einleitung</a>
            </li>
            <li>
                <a href="datenschutzrichtlinien#schlusswort">Schlusswort</a>
            </li>
        </ul>
        <h2 id="einleitung-ueberblick" class="adsimple-312757335">Einleitung und Überblick</h2>
        <p>Wir haben diese Datenschutzerklärung (Fassung 01.04.2024-312757335) verfasst, um Ihnen gemäß der Vorgaben der
            <a class="adsimple-312757335"
                href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312757335#d1e2269-1-1"
                target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen
            Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die
            von
            uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und
            welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu
            verstehen.<br />
            <strong class="adsimple-312757335">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir
            über
            Sie verarbeiten.
        </p>
        <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese
            Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich
            beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong
                class="adsimple-312757335">Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden
            Informationen
            geboten und <strong class="adsimple-312757335">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit
            in
            klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene
            Daten
            verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn
            man
            möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard
            sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und
            informativ
            und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
            Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte
            verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf
            Drittseiten
            anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
        <h2 id="anwendungsbereich" class="adsimple-312757335">Anwendungsbereich</h2>
        <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für
            alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit
            personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name,
            E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür,
            dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der
            Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
        <ul class="adsimple-312757335">
            <li class="adsimple-312757335">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
            <li class="adsimple-312757335">Social Media Auftritte und E-Mail-Kommunikation</li>
            <li class="adsimple-312757335">mobile Apps für Smartphones und andere Geräte</li>
        </ul>
        <p>
            <strong class="adsimple-312757335">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in
            denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden.
            Sollten
            wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls
            gesondert
            informieren.
        </p>
        <h2 id="rechtsgrundlagen" class="adsimple-312757335">Rechtsgrundlagen</h2>
        <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen
            Grundsätzen
            und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen,
            personenbezogene Daten zu verarbeiten.<br />
            Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND
            DES
            RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf
            EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-312757335"
                href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a>
            nachlesen.</p>
        <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
        <ol>
            <li class="adsimple-312757335">
                <strong class="adsimple-312757335">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben
                uns
                Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die
                Speicherung
                Ihrer eingegebenen Daten eines Kontaktformulars.
            </li>
            <li class="adsimple-312757335">
                <strong class="adsimple-312757335">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag
                oder
                vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel
                einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.
            </li>
            <li class="adsimple-312757335">
                <strong class="adsimple-312757335">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO):
                Wenn
                wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir
                gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel
                personenbezogene Daten.
            </li>
            <li class="adsimple-312757335">
                <strong class="adsimple-312757335">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im
                Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die
                Verarbeitung
                personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher
                und
                wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
            </li>
        </ol>
        <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher
            Gewalt
            sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche
            Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
        <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
        <ul class="adsimple-312757335">
            <li class="adsimple-312757335">In <strong class="adsimple-312757335">Österreich</strong> ist dies das
                Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong
                    class="adsimple-312757335">Datenschutzgesetz</strong>), kurz <strong
                    class="adsimple-312757335">DSG</strong>.</li>
            <li class="adsimple-312757335">In <strong class="adsimple-312757335">Deutschland</strong> gilt das <strong
                    class="adsimple-312757335">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-312757335">
                    BDSG</strong>.</li>
        </ul>
        <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden
            Abschnitten darüber.</p>
        <h2 id="kontaktdaten-verantwortliche" class="adsimple-312757335">Kontaktdaten des Verantwortlichen</h2>
        <p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie
            nachfolgend
            die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
            <span class="adsimple-312757335" style="font-weight: 400">Sebastian Rieder<br />
                Siedlung 37, 01744 Dippoldiswalde, Deutschland</span>
        </p>
        <p>E-Mail: <a href="mailto:my.portfolio@rieder-sebastian.de">my.portfolio&#64;rieder-sebastian.de</a>
            <br />
            Telefon: <a href="tel:+49 162 6923670">+49 162 6923670</a>
            <br />
            Impressum: <a href="https://www.rieder-sebastian.de/imprint/">https://www.rieder-sebastian.de/imprint/</a>
        </p>
        <h2 id="speicherdauer" class="adsimple-312757335">Speicherdauer</h2>
        <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer
            Dienstleistungen
            und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir
            personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In
            einigen
            Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu
            speichern, zum Beispiel zu Zwecken der Buchführung.</p>
        <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden
            die
            Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
        <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere
            Informationen dazu haben.</p>
        <h2 id="rechte-dsgvo" class="adsimple-312757335">Rechte laut Datenschutz-Grundverordnung</h2>
        <p>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu
            einer
            fairen und transparenten Verarbeitung von Daten kommt:</p>
        <ul class="adsimple-312757335">
            <li class="adsimple-312757335">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von
                Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die
                folgenden Informationen zu erfahren:
                <ul class="adsimple-312757335">
                    <li class="adsimple-312757335">zu welchem Zweck wir die Verarbeitung durchführen;</li>
                    <li class="adsimple-312757335">die Kategorien, also die Arten von Daten, die verarbeitet werden;
                    </li>
                    <li class="adsimple-312757335">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt
                        werden, wie die Sicherheit garantiert werden kann;</li>
                    <li class="adsimple-312757335">wie lange die Daten gespeichert werden;</li>
                    <li class="adsimple-312757335">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung
                        der
                        Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
                    <li class="adsimple-312757335">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu
                        diesen Behörden finden Sie weiter unten);</li>
                    <li class="adsimple-312757335">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;
                    </li>
                    <li class="adsimple-312757335">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet
                        werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
                </ul>
            </li>
            <li class="adsimple-312757335">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was
                bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
            <li class="adsimple-312757335">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
                Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
            <li class="adsimple-312757335">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung,
                was
                bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
            <li class="adsimple-312757335">Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was
                bedeutet,
                dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
            <li class="adsimple-312757335">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
                Durchsetzung
                eine Änderung der Verarbeitung mit sich bringt.
                <ul class="adsimple-312757335">
                    <li class="adsimple-312757335">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
                        (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f
                        (berechtigtes
                        Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so
                        rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
                    <li class="adsimple-312757335">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
                        jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach
                        nicht
                        mehr für Direktmarketing verwenden.</li>
                    <li class="adsimple-312757335">Werden Daten verwendet, um Profiling zu betreiben, können Sie
                        jederzeit
                        gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                        Profiling verwenden.</li>
                </ul>
            </li>
            <li class="adsimple-312757335">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
                ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung
                unterworfen zu werden.</li>
            <li class="adsimple-312757335">Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie
                können
                sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die
                Datenverarbeitung
                von personenbezogenen Daten gegen die DSGVO verstößt.</li>
        </ul>
        <p>
            <strong class="adsimple-312757335">Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben
            gelistete verantwortliche Stelle bei uns zu kontaktieren!
        </p>
        <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
            datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
            Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a
                class="adsimple-312757335" href="https://www.dsb.gv.at/?tid=312757335" target="_blank"
                rel="noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen
            Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a class="adsimple-312757335"
                href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener">Bundesbeauftragte
                für
                den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende
            lokale Datenschutzbehörde zuständig:</p>
        <h2 id="sicherheit-datenverarbeitung" class="adsimple-312757335">Sicherheit der Datenverarbeitung</h2>
        <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen
            umgesetzt.
            Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es
            im
            Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche
            Informationen
            schließen können.</p>
        <p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche
            Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware
            (z.
            B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen
            wir,
            falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
        <h2 id="kommunikation" class="adsimple-312757335">Kommunikation</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-312757335">Kommunikation Zusammenfassung</strong>
                        <br />
                        &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular
                        kommunizieren<br />
                        &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene
                        Formulardaten.
                        Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
                        &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
                        &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                        b
                        DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
            </tbody>
        </table>
        <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur
            Verarbeitung personenbezogener Daten kommen.</p>
        <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden
            Geschäftsvorgangs
            verarbeitet. Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
        <h3 class="adsimple-312757335">Betroffene Personen</h3>
        <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege
            den
            Kontakt zu uns suchen.</p>
        <h3 class="adsimple-312757335">Telefon</h3>
        <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten
            Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im
            Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht,
            sobald
            der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
        <h3 class="adsimple-312757335">E-Mail</h3>
        <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer,
            Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die
            Daten
            werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
        <h3 class="adsimple-312757335">Online Formulare</h3>
        <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und
            gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der
            Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
        <h3 class="adsimple-312757335">Rechtsgrundlagen</h3>
        <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
        <ul class="adsimple-312757335">
            <li class="adsimple-312757335">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung
                Ihre
                Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
            <li class="adsimple-312757335">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die
                Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir
                müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots,
                verarbeiten;
            </li>
            <li class="adsimple-312757335">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen
                Kundenanfragen
                und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische
                Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die
                Kommunikation effizient betreiben zu können.</li>
        </ul>
        <h2 id="auftragsverarbeitungsvertrag-avv" class="adsimple-312757335">Auftragsverarbeitungsvertrag (AVV)</h2>
        <p>In diesem Abschnitt möchten wir Ihnen erklären, was ein Auftragsverarbeitungsvertrag ist und warum dieser
            benötigt wird. Weil das Wort &#8220;Auftragsverarbeitungsvertrag&#8221; ein ziemlicher Zungenbrecher ist,
            werden
            wir hier im Text auch öfters nur das Akronym AVV benutzen. Wie die meisten Unternehmen arbeiten wir nicht
            alleine, sondern nehmen auch selbst Dienstleistungen anderer Unternehmen oder Einzelpersonen in Anspruch. 
            Durch
            die Einbeziehung verschiedener Unternehmen bzw. Dienstleister kann es sein, dass wir  personenbezogene Daten
            zur
            Verarbeitung weitergeben. Diese Partner fungieren dann als Auftragsverarbeiter, mit denen wir einen Vertrag,
            den
            sogenannten Auftragsverarbeitungsvertrag (AVV), abschließen. Für Sie am wichtigsten zu wissen ist, dass die
            Verarbeitung Ihrer personenbezogenen Daten ausschließlich nach unserer Weisung erfolgt und durch den AVV
            geregelt werden muss.</p>
        <h3 class="adsimple-312757335">Wer sind Auftragsverarbeiter?</h3>
        <p>Wir sind als Unternehmen und Websiteinhaber für alle Daten, die wir von Ihnen verarbeiten verantwortlich.
            Neben
            den Verantwortlichen kann es auch sogenannte Auftragsverarbeiter geben. Dazu zählt jedes Unternehmen bzw.
            jede
            Person, die in unserem Auftrag personenbezogene Daten verarbeitet. Genauer und nach der DSGVO-Definition
            gesagt:
            jede natürliche oder juristische Person, Behörde, Einrichtung oder eine andere Stelle, die in unserem
            Auftrag
            personenbezogene Daten verarbeitet, gilt als Auftragsverarbeiter. Auftragsverarbeiter können folglich
            Dienstleister wie Hosting- oder Cloudanbieter, Bezahlungs- oder Newsletter-Anbieter oder große Unternehmen
            wie
            beispielsweise Google oder Microsoft sein.</p>
        <p>Zur besseren Verständlichkeit der Begrifflichkeiten hier ein Überblick über die drei Rollen in der DSGVO:</p>
        <p>
            <strong class="adsimple-312757335">Betroffener</strong> (Sie als Kunde oder Interessent) → <strong
                class="adsimple-312757335">Verantwortlicher</strong> (wir als Unternehmen und Auftraggeber) → <strong
                class="adsimple-312757335">Auftragsverarbeiter</strong> (Dienstleister wie z. B. Webhoster oder
            Cloudanbieter)
        </p>
        <h3 class="adsimple-312757335">Inhalt eines Auftragsverarbeitungsvertrages</h3>
        <p>Wie bereits oben erwähnt, haben wir mit unseren Partnern, die als Auftragsverarbeiter fungieren, einen AVV
            abgeschlossen. Darin wird allen voran festgehalten, dass der Auftragsverarbeiter die zu bearbeitenden Daten
            ausschließlich gemäß der DSGVO verarbeitet. Der Vertrag muss schriftlich abgeschlossen werden, allerdings
            gilt
            in diesem Zusammenhang auch der elektronische Vertragsabschluss als „schriftlich“. Erst auf der Grundlage
            des
            Vertrags erfolgt die Verarbeitung der personenbezogenen Daten. Im Vertrag muss folgendes enthalten sein:</p>
        <ul class="adsimple-312757335">
            <li class="adsimple-312757335">Bindung an uns als Verantwortlichen</li>
            <li class="adsimple-312757335">Pflichten und Rechte des Verantwortlichen</li>
            <li class="adsimple-312757335">Kategorien betroffener Personen</li>
            <li class="adsimple-312757335">Art der personenbezogenen Daten</li>
            <li class="adsimple-312757335">Art und Zweck der Datenverarbeitung</li>
            <li class="adsimple-312757335">Gegenstand und Dauer der Datenverarbeitung</li>
            <li class="adsimple-312757335">Durchführungsort der Datenverarbeitung</li>
        </ul>
        <p>Weiters enthält der Vertrag alle Pflichten des Auftragsverarbeiters. Die wichtigsten Pflichten sind:</p>
        <ul class="adsimple-312757335">
            <li class="adsimple-312757335">Maßnahmen zur Datensicherheit zu gewährleisten</li>
            <li class="adsimple-312757335">mögliche technische und organisatorischen Maßnahmen zu treffen, um die Rechte
                der
                betroffenen Person zu schützen</li>
            <li class="adsimple-312757335">ein Daten-Verarbeitungsverzeichnis zu führen</li>
            <li class="adsimple-312757335">auf Anfrage der Datenschutz-Aufsichtsbehörde mit dieser zusammenzuarbeiten
            </li>
            <li class="adsimple-312757335">eine Risikoanalyse in Bezug auf die erhaltenen personenbezogenen Daten
                durchzuführen</li>
            <li class="adsimple-312757335">Sub-Auftragsverarbeiter dürfen nur mit schriftlicher Genehmigung des
                Verantwortlichen beauftragt werden</li>
        </ul>
        <p>Wie so eine AVV konkret aussieht, können Sie sich beispielsweise unter <a class="adsimple-312757335"
                href="https://www.wko.at/service/wirtschaftsrecht-gewerberecht/eu-dsgvo-mustervertrag-auftragsverarbeitung.html">https://www.wko.at/service/wirtschaftsrecht-gewerberecht/eu-dsgvo-mustervertrag-auftragsverarbeitung.html</a>
            ansehen. Hier wird ein Mustervertrag vorgestellt.</p>
        <h2 id="cookies" class="adsimple-312757335">Cookies</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-312757335">Cookies Zusammenfassung</strong>
                        <br />
                        &#x1f465; Betroffene: Besucher der Website<br />
                        &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw.
                        beim
                        Hersteller der Software, der das Cookie setzt.<br />
                        &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden
                        Sie
                        weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
                        &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren
                        variieren<br />
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1
                        lit.f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-312757335">Was sind Cookies?</h3>
        <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br />
            Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
            Datenschutzerklärung besser verstehen.</p>
        <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
            Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine
            Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
        <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden
            Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere
            Anwendungsbereiche
            gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese
            Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers,
            untergebracht.
            Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein
            oder
            mehrere Attribute angegeben werden.</p>
        <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
            Seiteneinstellungen.
            Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere
            Seite
            zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt
            sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind
            alle
            Cookies in einer einzigen Datei gespeichert.</p>
        <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem
            Webserver.
            Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser
            erneut
            verwendet, sobald eine andere Seite angefordert wird.</p>
            <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
                alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="" />
        <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
            unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt.
            Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines
            Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
            enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres
            PCs
            zugreifen.</p>
        <p>So können zum Beispiel Cookie-Daten aussehen:</p>
        <p>
            <strong class="adsimple-312757335">Name:</strong> _ga<br />
            <strong class="adsimple-312757335">Wert:</strong> GA1.2.1326744211.152312757335-9<br />
            <strong class="adsimple-312757335">Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br />
            <strong class="adsimple-312757335">Ablaufdatum:</strong> nach 2 Jahren
        </p>
        <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
        <ul class="adsimple-312757335">
            <li class="adsimple-312757335">Mindestens 4096 Bytes pro Cookie</li>
            <li class="adsimple-312757335">Mindestens 50 Cookies pro Domain</li>
            <li class="adsimple-312757335">Mindestens 3000 Cookies insgesamt</li>
        </ul>
        <h3 class="adsimple-312757335">Welche Arten von Cookies gibt es?</h3>
        <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den
            folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
            verschiedenen
            Arten von HTTP-Cookies eingehen.</p>
        <p>Man kann 4 Arten von Cookies unterscheiden:</p>
        <p>
            <strong class="adsimple-312757335">Unerlässliche Cookies<br />
            </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel
            braucht
            es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und
            später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein
            Browserfenster schließt.
        </p>
        <p>
            <strong class="adsimple-312757335">Zweckmäßige Cookies<br />
            </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt.
            Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen
            Browsern
            gemessen.
        </p>
        <p>
            <strong class="adsimple-312757335">Zielorientierte Cookies<br />
            </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene
            Standorte, Schriftgrößen oder Formulardaten gespeichert.
        </p>
        <p>
            <strong class="adsimple-312757335">Werbe-Cookies<br />
            </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell
            angepasste
            Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
        </p>
        <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie
            zulassen
            möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
        <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a
                class="adsimple-312757335"
                href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>, dem
            Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management
            Mechanism&#8221;.</p>
        <h3 class="adsimple-312757335">Zweck der Verarbeitung über Cookies</h3>
        <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw.
            beim
            Hersteller der Software, die das Cookie setzt.</p>
        <h3 class="adsimple-312757335">Welche Daten werden verarbeitet?</h3>
        <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert
            werden,
            kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über
            die
            verarbeiteten bzw. gespeicherten Daten informieren.</p>
        <h3 class="adsimple-312757335">Speicherdauer von Cookies</h3>
        <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach
            weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
        <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies
            jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf
            einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit
            der
            Speicherung bis dahin unberührt bleibt.</p>
        <h3 class="adsimple-312757335">Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
        <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
            Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur
            teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen
            Cookies
            zulassen.</p>
        <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
            Cookie-Einstellungen
            ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
        <p>
            <a class="adsimple-312757335" href="https://support.google.com/chrome/answer/95647?tid=312757335"
                target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und
                verwalten</a>
        </p>
        <p>
            <a class="adsimple-312757335"
                href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=312757335" target="_blank"
                rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
        </p>
        <p>
            <a class="adsimple-312757335"
                href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=312757335"
                target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites
                auf
                Ihrem Computer abgelegt haben</a>
        </p>
        <p>
            <a class="adsimple-312757335"
                href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=312757335">Internet
                Explorer: Löschen und Verwalten von Cookies</a>
        </p>
        <p>
            <a class="adsimple-312757335"
                href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=312757335">Microsoft
                Edge: Löschen und Verwalten von Cookies</a>
        </p>
        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
            informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob
            Sie
            das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die
            Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren
            Chrome&#8221;
            im Falle eines Chrome Browsers.</p>
        <h3 class="adsimple-312757335">Rechtsgrundlage</h3>
        <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von
            Cookies
            eine <strong class="adsimple-312757335">Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen
            verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese
            Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 165 Abs. 3 des
            Telekommunikationsgesetzes (2021). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht
            umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des
            Telemediengesetzes
            (TMG).</p>
        <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen <strong
                class="adsimple-312757335">berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den
            meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme
            Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
        <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer
            Einwilligung.
            Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
        <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte
            Software Cookies verwendet.</p>
        <h2 id="kundendaten" class="adsimple-312757335">Kundendaten</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-312757335">Kundendaten Zusammenfassung</strong>
                        <br />
                        &#x1f465; Betroffene: Kunden bzw. Geschäfts- und Vertragspartner<br />
                        &#x1f91d; Zweck: Erbringung der vertraglich oder vorvertraglich vereinbarten Leistungen
                        einschließlich zugehörige Kommunikation<br />
                        &#x1f4d3; Verarbeitete Daten: Name, Adresse, Kontaktdaten, E-Mail-Adresse, Telefonnummer,
                        Zahlungsinformationen (wie z. B. Rechnungen und Bankdaten), Vertragsdaten (wie z. B. Laufzeit
                        und
                        Gegenstand des Vertrags), IP-Adresse, Bestelldaten<br />
                        &#x1f4c5; Speicherdauer: die Daten werden gelöscht, sobald sie zur Erbringung unserer
                        geschäftlichen
                        Zwecke nicht mehr erforderlich sind und es keine gesetzliche Aufbewahrungspflicht gibt.<br />
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO), Vertrag
                        (Art. 6 Abs 1 lit. b DSGVO)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-312757335">Was sind Kundendaten?</h3>
        <p>Damit wir unser Service bzw. unsere vertraglichen Leistungen anbieten können, verarbeiten wir auch Daten
            unserer
            Kunden und Geschäftspartner. Unter diesen Daten befinden sich immer auch personenbezogene Daten. Unter
            Kundendaten versteht man alle Informationen, die auf Basis einer vertraglichen oder vorvertraglichen
            Zusammenarbeit verarbeitet werden, um die angebotenen Leistungen erbringen zu können. Kundendaten sind also
            alle
            gesammelten Informationen, die wir über unsere Kunden erheben und verarbeiten.</p>
        <h3 class="adsimple-312757335">Warum verarbeiten wir Kundendaten?</h3>
        <p>Es gibt viele Gründe, warum wir Kundendaten sammeln und verarbeiten. Der wichtigste ist, dass wir zur
            Bereitstellung unserer Services einfach verschiedene Daten benötigen. Manchmal reicht hier schon Ihre
            E-Mail-Adresse, doch wenn Sie etwa ein Produkt oder eine Dienstleistung erwerben, benötigen wir auch Daten
            wie
            beispielsweise Name, Adresse, Bankdaten oder Vertragsdaten. Wir nutzen die Daten in weiterer Folge auch für
            Marketing- und Vertriebsoptimierungen, damit wir insgesamt unser Service für unsere Kunden verbessern
            können.
            Ein weiterer wichtiger Punkt ist auch unser Kundenservice, der uns stets sehr am Herzen liegt. Wir wollen,
            dass
            Sie mit Frage zu unseren Angeboten jederzeit zu uns kommen können und dafür brauchen wir zumindest Ihre
            E-Mail-Adresse.</p>
        <h3 class="adsimple-312757335">Welche Daten werden verarbeitet?</h3>
        <p>Welche Daten genau gespeichert werden, kann an dieser Stelle nur anhand Kategorien wiedergegeben werden. Dies
            hängt nämlich stets davon ab, welche Leistungen Sie von uns beziehen. In manchen Fällen geben Sie uns
            lediglich
            Ihre E-Mail-Adresse, damit wir beispielsweise mit Ihnen in Kontakt treten können oder wir Ihre Fragen
            beantworten können. In anderen Fällen erwerben Sie ein Produkt oder eine Dienstleistung bei uns und dafür
            benötigen wir deutlich mehr Informationen, wie etwa Ihre Kontaktdaten, Zahlungsdaten und Vertragsdaten.</p>
        <p>Hier eine Liste möglicher Daten, die wir von Ihnen erhalten und verarbeiten:</p>
        <ul class="adsimple-312757335">
            <li class="adsimple-312757335">Name</li>
            <li class="adsimple-312757335">Kontaktadresse</li>
            <li class="adsimple-312757335">E-Mail-Adresse</li>
            <li class="adsimple-312757335">Telefonnummer</li>
            <li class="adsimple-312757335">Geburtsdatum</li>
            <li class="adsimple-312757335">Zahlungsdaten (Rechnungen, Bankdaten, Zahlungshistorie etc.)</li>
            <li class="adsimple-312757335">Vertragsdaten ( Laufzeit, Inhalt)</li>
            <li class="adsimple-312757335">Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
            <li class="adsimple-312757335">Metadaten (IP-Adresse, Geräte-Informationen)</li>
        </ul>
        <h3 class="adsimple-312757335">Wie lange werden die Daten gespeichert?</h3>
        <p>Sobald wir die Kundendaten zur Erfüllung unserer vertraglichen Pflichten und unserer Zwecke nicht mehr
            benötigen
            und die Daten auch nicht für mögliche Gewährleistungs- und Haftungspflichten nötig sind, löschen wir die
            entsprechenden Kundendaten. Das ist zum Beispiel der Fall, wenn ein geschäftlicher Vertrag endet. Danach
            beträgt
            die Verjährungsfrist in der Regel 3 Jahre, wobei längere Fristen im Einzelfall möglich sind. Wir halten uns
            natürlich auch an die gesetzlichen Aufbewahrungspflichten. Ihre Kundendaten werden ganz sicher nicht an
            Dritte
            weitergegeben, wenn Sie dazu nicht explizit eine Einwilligung erteilt haben.</p>
        <h3 class="adsimple-312757335">Rechtsgrundlage</h3>
        <p>Rechtsgrundlagen für die Verarbeitung Ihrer Daten sind Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs
            1
            lit. b DSGVO (Vertrag bzw. vorvertragliche Maßnahmen), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen) 
            und
            in speziellen Fällen (z. B. bei medizinischen Leistungen) Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung
            besonderer
            Kategorien).</p>
        <p>Für den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung gemäß Art. 9 Abs. 2 lit.
            c.
            DSGVO. Für Zwecke der Gesundheitsversorgung, der Arbeitsmedizin, für die medizinische Diagnostik, für die
            Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder für die Verwaltung von Systemen und
            Diensten
            im Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten gemäß Art. 9 Abs. 2 lit.
            h.
            DSGVO. Wenn Sie freiwillig Daten der besonderen Kategorien mitteilen, erfolgt die Verarbeitung auf Grundlage
            von
            Art. 9 Abs. 2 lit. a. DSGVO.</p>
        <h2 id="social-media-einleitung" class="adsimple-312757335">Social Media Einleitung</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-312757335">Social Media Datenschutzerklärung Zusammenfassung</strong>
                        <br />
                        &#x1f465; Betroffene: Besucher der Website<br />
                        &#x1f91d; Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern,
                        Interessenten u.a., Werbung<br />
                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten,
                        Daten
                        zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
                        Mehr Details dazu finden Sie beim jeweils eingesetzten Social-Media-Tool.<br />
                        &#x1f4c5; Speicherdauer: abhängig von den verwendeten Social-Media-Plattformen<br />
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                        f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-312757335">Was ist Social Media?</h3>
        <p>Zusätzlich zu unserer Website sind wir auch in diversen Social-Media-Plattformen aktiv. Dabei können Daten
            von
            Usern verarbeitet werden, damit wir gezielt User, die sich für uns interessieren, über die sozialen
            Netzwerke
            ansprechen können. Darüber hinaus können auch Elemente einer Social-Media-Plattform direkt in unsere Website
            eingebettet sein. Das ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf unserer Website
            anklicken
            und direkt zu unserem Social-Media-Auftritt weitergeleitet werden. Als sogenannte Sozialen Medien oder
            Social
            Media werden Websites und Apps bezeichnet, über die angemeldete Mitglieder Inhalte produzieren, Inhalte
            offen
            oder in bestimmten Gruppen austauschen und sich mit anderen Mitgliedern vernetzen können.</p>
        <h3 class="adsimple-312757335">Warum nutzen wir Social Media?</h3>
        <p>Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online kommunizieren und in Kontakt treten.
            Mit
            unseren Social-Media-Auftritten können wir unsere Produkte und Dienstleistungen Interessenten näherbringen.
            Die
            auf unserer Website eingebundenen Social-Media-Elemente helfen Ihnen, schnell und ohne Komplikationen zu
            unseren
            Social-Media-Inhalten wechseln können.</p>
        <p>Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert und verarbeitet werden, haben in
            erster
            Linie den Zweck, Webanalysen durchführen zu können. Ziel dieser Analysen ist es, genauere und
            personenbezogene
            Marketing- und Werbestrategien entwickeln zu können. Abhängig von Ihrem Verhalten auf einer
            Social-Media-Plattform, können mit Hilfe der ausgewerteten Daten, passende Rückschlüsse auf Ihre Interessen
            getroffen werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen auch möglich, Ihnen
            maßgeschneiderte Werbeanzeigen zu präsentieren. Meistens werden für diesen Zweck Cookies in Ihrem Browser
            gesetzt, die Daten zu Ihrem Nutzungsverhalten speichern.</p>
        <p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste
            einer Social-Media-Plattform nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in bestimmten
            Fällen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art.
            26
            DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer
            diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist dann weiter unten bei der betroffenen
            Plattform wiedergegeben.</p>
        <p>Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen oder unserer eingebauten Elemente auch
            Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können, da viele Social-Media-Kanäle,
            beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre
            Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
        <h3 class="adsimple-312757335">Welche Daten werden verarbeitet?</h3>
        <p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen Anbieter der
            Social-Media-Plattform
            ab. Aber für gewöhnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in
            ein
            Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wen Sie liken oder wem
            folgen, wann Sie welche Seiten besucht haben, Informationen zu Ihrem Gerät und Ihre IP-Adresse. Die meisten
            dieser Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein Profil bei dem besuchten
            Social-Media-Kanal haben und angemeldet sind, können Daten mit Ihrem Profil verknüpft werden.</p>
        <p>Alle Daten, die über eine Social-Media-Plattform erhoben werden, werden auch auf den Servern der Anbieter
            gespeichert. Somit haben auch nur die Anbieter Zugang zu den Daten und können Ihnen die passenden Auskünfte
            geben bzw. Änderungen vornehmen.</p>
        <p>Wenn Sie genau wissen wollen, welche Daten bei den Social-Media-Anbietern gespeichert und verarbeitet werden
            und
            wie sie der Datenverarbeitung widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des
            Unternehmens sorgfältig durchlesen. Auch wenn Sie zur Datenspeicherung und Datenverarbeitung Fragen haben
            oder
            entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich direkt an den Anbieter wenden.</p>
        <h3 class="adsimple-312757335">
            <span class="adsimple-312757335"
                data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}"
                data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer
                der Datenverarbeitung</span>
        </h3>
        <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu
            haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie für den eigenen Zweck
            nicht
            mehr benötigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon
            innerhalb
            von zwei Tagen gelöscht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die
            Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im
            Fall
            von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
        <h3 class="adsimple-312757335">Widerspruchsrecht</h3>
        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
            Drittanbietern wie eingebettete Social-Media-Elemente zu widerrufen. Das funktioniert entweder über unser
            Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung
            durch
            Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
        <p>Da bei Social-Media-Tools Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine
            Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet
            werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
        <h3 class="adsimple-312757335">Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und
            gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
                class="adsimple-312757335">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten bei
            Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong
                class="adsimple-312757335">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten
            Kommunikation
            mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools
            gleichwohl
            nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch
            Cookies in
            Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau
            durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters
            anzusehen.
        </p>
        <p>Informationen zu speziellen Social-Media-Plattformen erfahren Sie &#8211; sofern vorhanden &#8211; in den
            folgenden Abschnitten.</p>
        <h2 id="externe-onlineplattformen-einleitung" class="adsimple-312757335">Externe Onlineplattformen Einleitung
        </h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-312757335">Externe Onlineplattformen Datenschutzerklärung
                            Zusammenfassung</strong>
                        <br />
                        &#x1f465; Betroffene: Besucher der Website bzw. Besucher der externen Onlineplattformen<br />
                        &#x1f91d; Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern,
                        Interessenten<br />
                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten,
                        Daten
                        zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
                        Mehr Details dazu finden Sie bei der jeweils eingesetzten Plattform.<br />
                        &#x1f4c5; Speicherdauer: abhängig von den verwendeten Plattformen<br />
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                        f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-312757335">Was sind externe Onlineplattformen?</h3>
        <p>Um unsere Leistungen oder Produkte auch außerhalb unserer Website anbieten zu können, nutzen wir auch externe
            Plattformen. Dabei handelt es sich meist um Online-Marktplätze wie etwa Amazon oder eBay. Neben unserer
            Verantwortlichkeit des Datenschutzes gelten auch die Datenschutzbestimmungen der von uns verwendeten
            externen
            Plattformen. Dies ist speziell dann der Fall, wenn über die Plattform unsere Produkte erworben werden. Also
            wenn
            es einen Zahlungsvorgang gibt. Weiters nützen die meisten Plattformen Ihre Daten auch, um die eigenen
            Marketingmaßnahmen zu optimieren. Beispielsweise kann die Plattform, mit Hilfe erhobener Daten,
            Werbeanzeigen
            genau an die Interessen der Kunden und Websitebesucher zuschneiden.</p>
        <h3 class="adsimple-312757335">Warum nutzen wir externe Onlineplattformen?</h3>
        <p>Neben unserer Website wollen wir unser Angebot auch auf anderen Plattformen anbieten, um mehr Kunden unser
            Angebot näher zu bringen. Externe Online-Marktplätze wie etwa Amazon, Ebay oder auch Digistore24 bieten
            große
            Verkaufswebsites, die unsere Produkte Menschen anbieten, die unter Umständen nicht unsere Website kennen. Es
            kann auch vorkommen, dass eingebaute Elemente auf unserer Seite zu einer externen Onlineplattform leiten.
            Daten,
            die von der verwendeten Onlineplattform verarbeitet und gespeichert werden, dienen dem Unternehmen
            einerseits
            den Zahlungsvorgang zu protokollieren, andererseits aber auch um Webanalysen durchführen zu können.</p>
        <p>Ziel dieser Analysen ist es, genauere und personenbezogene Marketing- und Werbestrategien entwickeln zu
            können.
            Abhängig von Ihrem Verhalten auf einer Plattform, können mit Hilfe der ausgewerteten Daten, passende
            Rückschlüsse auf Ihre Interessen getroffen werden und sogenannte Userprofile erstellt werden. So ist es den
            Plattformen auch möglich, Ihnen maßgeschneiderte Werbeanzeigen bzw. Produkte zu präsentieren. Meistens
            werden
            für diesen Zweck Cookies in Ihrem Browser gesetzt, die Daten zu Ihrem Nutzungsverhalten speichern.</p>
        <p>Bitte beachten Sie, dass bei der Nutzung der Plattformen oder unserer eingebauten Elemente auch Daten von
            Ihnen
            außerhalb der Europäischen Union verarbeitet werden können, da Onlineplattformen, beispielsweise Amazon oder
            eBay, amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre
            personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
        <h3 class="adsimple-312757335">Welche Daten werden verarbeitet?</h3>
        <p>Welche Daten genau gespeichert und verarbeitet werden, hängt von der jeweiligen externen Plattform ab. Aber
            für
            gewöhnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein
            Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wann Sie welche Seiten
            besucht haben, Informationen zu Ihrem Gerät und Ihre IP-Adresse. Sehr häufig werden die meisten dieser Daten
            in
            Cookies gespeichert. Wenn Sie bei einer externen Plattform ein eigenes Profil haben und dort auch angemeldet
            sind, können Daten mit dem Profil verknüpft werden. Die erhobenen Daten werden auf den Servern der
            verwendeten
            Plattformen gespeichert und dort verarbeitet. Wie eine externe Plattform Daten genau speichert, verwaltet
            und
            verarbeitet, erfahren Sie in der jeweiligen Datenschutzerklärung. Falls Sie zur Datenspeicherung und
            Datenverarbeitung Fragen haben oder entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich
            direkt
            an die Plattform zu wenden.</p>
        <h3 class="adsimple-312757335">Dauer der Datenverarbeitung</h3>
        <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu
            haben. Beispielsweise speichert Amazon Daten, bis sie für den eigenen Zweck nicht mehr benötigt werden.
            Generell
            verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen
            und
            Produkte unbedingt notwendig ist.</p>
        <h3 class="adsimple-312757335">Widerspruchsrecht</h3>
        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies zu
            widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über Opt-Out-Funktionen bei der
            jeweiligen externen Plattform. Weiters können Sie auch die Datenerfassung durch Cookies verhindern, indem
            Sie in
            Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
        <p>Da Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über
            Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die
            Datenschutzerklärungen der jeweiligen externen Plattformen durchlesen.</p>
        <h3 class="adsimple-312757335">Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch externe Plattformen verarbeitet und gespeichert
            werden
            können, gilt diese <strong class="adsimple-312757335">Einwilligung</strong> als Rechtsgrundlage der
            Datenverarbeitung <strong class="adsimple-312757335">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich
            werden
            Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage eines <strong
                class="adsimple-312757335">berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer
            schnellen
            und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet.
            Falls
            wir eingebundene Elemente externer Plattformen auf unser Website haben, setzen wir diese gleichwohl nur ein,
            soweit Sie eine Einwilligung erteilt haben.</p>
        <p>Informationen zu speziellen externen Plattformen erfahren Sie &#8211; sofern vorhanden &#8211; in den
            folgenden
            Abschnitten.</p>
        <h2 id="audio-video-einleitung" class="adsimple-312757335">Audio &amp; Video Einleitung</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-312757335">Audio &amp; Video Datenschutzerklärung
                            Zusammenfassung</strong>
                        <br />
                        &#x1f465; Betroffene: Besucher der Website<br />
                        &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten,
                        Informationen
                        zu Ihrem Gerät und Ihre IP-Adresse können gespeichert werden.<br />
                        Mehr Details dazu finden Sie weiter unten in den entsprechenden Datenschutztexten.<br />
                        &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert, solange sie für den
                        Dienstzweck
                        nötig sind<br />
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                        f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-312757335">Was sind Audio- und Videoelemente?</h3>
        <p>Wir haben auf unsere Website Audio- bzw. Videoelemente eingebunden, damit Sie sich direkt über unsere Website
            etwa Videos ansehen oder Musik/Podcasts anhören können. Die Inhalte werden von Dienstanbietern zur Verfügung
            gestellt. Alle Inhalte werden also auch von den entsprechenden Servern der Anbieter bezogen.</p>
        <p>Es handelt sich dabei um eingebundene Funktionselemente von Plattformen wie etwa YouTube, Vimeo oder Spotify.
            Die
            Nutzung dieser Portale ist in der Regel kostenlos, es können aber auch kostenpflichtige Inhalte
            veröffentlicht
            werden. Mit Hilfe dieser eingebundenen Elemente könne Sie sich über unsere Website die jeweiligen Inhalte
            anhören oder ansehen.</p>
        <p>Wenn Sie Audio- oder Videoelemente auf unsere Website verwenden, können auch personenbezogene Daten von Ihnen
            an
            die Dienstanbieter übermittelt, verarbeitet und gespeichert werden.</p>
        <h3 class="adsimple-312757335">Warum verwenden wir Audio- &amp; Videoelemente auf unserer Website?</h3>
        <p>Natürlich wollen wir Ihnen auf unserer Website das beste Angebot liefern. Und uns ist bewusst, dass Inhalte
            nicht
            mehr bloß in Text und statischem Bild vermittelt werden. Statt Ihnen einfach nur einen Link zu einem Video
            zu
            geben, bieten wir Ihnen direkt auf unserer Website Audio- und Videoformate, die unterhaltend oder informativ
            und
            im Idealfall sogar beides sind. Das erweitert unser Service und erleichtert Ihnen den Zugang zu
            interessanten
            Inhalten. Somit bieten wir neben unseren Texten und Bildern auch Video und/oder Audio-Inhalte an.</p>
        <h3 class="adsimple-312757335">Welche Daten werden durch Audio- &amp; Videoelemente gespeichert?</h3>
        <p>Wenn Sie eine Seite auf unserer Website aufrufen, die beispielsweise ein eingebettetes Video hat, verbindet
            sich
            Ihr Server mit dem Server des Dienstanbieters. Dabei werden auch Daten von Ihnen an den Drittanbieter
            übertragen
            und dort gespeichert. Manche Daten werden ganz unabhängig davon, ob Sie bei dem Drittanbieter ein Konto
            haben
            oder nicht, gesammelt und gespeichert. Dazu zählen meist Ihre IP-Adresse, Browsertyp, Betriebssystem, und
            weitere allgemeine Informationen zu Ihrem Endgerät. Weiters werden von den meisten Anbietern auch
            Informationen
            über Ihre Webaktivität eingeholt. Dazu zählen etwa Sitzungsdauer, Absprungrate, auf welchen Button Sie
            geklickt
            haben oder über welche Website Sie den Dienst nutzen. All diese Informationen werden meist über Cookies oder
            Pixel-Tags (auch Web Beacon genannt) gespeichert. Pseudonymisierte Daten werden meist in Cookies in Ihrem
            Browser gespeichert. Welche Daten genau gespeichert und verarbeitet werden, erfahren Sie stets in der
            Datenschutzerklärung des jeweiligen Anbieters.</p>
        <h3 class="adsimple-312757335">Dauer der Datenverarbeitung</h3>
        <p>Wie lange die Daten auf den Servern der Drittanbieter genau gespeichert werden, erfahren Sie entweder weiter
            unten im Datenschutztext des jeweiligen Tools oder in der Datenschutzerklärung des Anbieters. Grundsätzlich
            werden personenbezogene Daten immer nur so lange verarbeitet, wie es für die Bereitstellung unserer
            Dienstleistungen oder Produkte unbedingt nötig wird. Dies gilt in der Regel auch für Drittanbieter. Meist
            können
            Sie davon ausgehen, dass gewisse Daten über mehrere Jahre auf den Servern der Drittanbieter gespeichert
            werden.
            Daten können speziell in Cookies unterschiedlich lange gespeichert werden. Manche Cookies werden bereits
            nach
            dem Verlassen der Website wieder gelöscht, anderen können über einige Jahre in Ihrem Browser gespeichert
            sein.
        </p>
        <h3 class="adsimple-312757335">Widerspruchsrecht</h3>
        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
            Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere
            Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in
            Ihrem
            Browser die Cookies verwalten, deaktivieren oder löschen. Die Rechtmäßigkeit der Verarbeitung bis zum
            Widerruf
            bleibt unberührt.</p>
        <p>Da durch die eingebundenen Audio- und Video-Funktionen auf unserer Seite meist auch Cookies verwendet werden,
            sollte Sie sich auch unsere allgemeine Datenschutzerklärung über Cookies durchlesen. In den
            Datenschutzerklärungen der jeweiligen Drittanbieter erfahren Sie genaueres über den Umgang und die
            Speicherung
            Ihrer Daten.</p>
        <h3 class="adsimple-312757335">Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Audio- und Video-Elemente verarbeitet
            und
            gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
                class="adsimple-312757335">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch
            auf
            Grundlage unseres berechtigten Interesses <strong class="adsimple-312757335">(Art. 6 Abs. 1 lit. f
                DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und
            Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundenen Audio- und Video-Elemente
            gleichwohl
            nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
        <h2 id="webdesign-einleitung" class="adsimple-312757335">Webdesign Einleitung</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-312757335">Webdesign Datenschutzerklärung Zusammenfassung</strong>
                        <br />
                        &#x1f465; Betroffene: Besucher der Website<br />
                        &#x1f91d; Zweck: Verbesserung der Nutzererfahrung<br />
                        &#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten
                        Diensten ab. Meist handelt es sich etwa um IP-Adresse, technische Daten, Spracheinstellungen, 
                        Browserversion, Bildschirmauflösung und Name des Browsers. Mehr Details dazu finden Sie bei den
                        jeweils eingesetzten Webdesign-Tools.<br />
                        &#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools<br />
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                        f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-312757335">Was ist Webdesign?</h3>
        <p>Wir verwenden auf unserer Website verschiedene Tools, die unserem Webdesign dienen. Bei Webdesign geht es
            nicht,
            wie oft angenommen, nur darum, dass unsere Website hübsch aussieht, sondern auch um Funktionalität und
            Leistung.
            Aber natürlich ist die passende Optik einer Website auch eines der großen Ziele professionellen Webdesigns.
            Webdesign ist ein Teilbereich des Mediendesigns und beschäftigt sich sowohl mit der visuellen als auch der
            strukturellen und funktionalen Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign Ihre Erfahrung
            auf
            unserer Website zu verbessern. Im Webdesign-Jargon spricht man in diesem Zusammenhang von User-Experience
            (UX)
            und Usability. Unter User Experience versteht man alle Eindrücke und Erlebnisse, die der Websitebesucher auf
            einer Website erfährt. Ein Unterpunkt der User Experience ist die Usability. Dabei geht es um die
            Nutzerfreundlichkeit einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte, Unterseiten oder
            Produkte klar strukturiert sind und Sie leicht und schnell finden, wonach Sie suchen. Um Ihnen die
            bestmögliche
            Erfahrung auf unserer Website zu bieten, verwenden wir auch sogenannte Webdesign-Tools von Drittanbietern.
            Unter
            die Kategorie „Webdesign“ fallen in dieser Datenschutzerklärung also alle Dienste, die unsere Website
            gestalterisch verbessern. Das können beispielsweise Schriftarten, diverse Plugins oder andere eingebundene
            Webdesign-Funktionen sein.</p>
        <h3 class="adsimple-312757335">Warum verwenden wir Webdesign-Tools?</h3>
        <p>Wie Sie Informationen auf einer Website aufnehmen, hängt sehr stark von der Struktur, der Funktionalität und
            der
            visuellen Wahrnehmung der Website ab. Daher wurde auch für uns ein gutes und professionelles Webdesign immer
            wichtiger. Wir arbeiten ständig an der Verbesserung unserer Website und sehen dies auch als erweiterte
            Dienstleistung für Sie als Websitebesucher. Weiters hat eine schöne und funktionierende Website auch
            wirtschaftliche Vorteile für uns. Schließlich werden Sie uns nur besuchen und unsere Angebote in Anspruch
            nehmen, wenn Sie sich rundum wohl fühlen.</p>
        <h3 class="adsimple-312757335">Welche Daten werden durch Webdesign-Tools gespeichert?</h3>
        <p>Wenn Sie unsere Website besuchen, können Webdesign-Elemente in unseren Seiten eingebunden sein, die auch
            Daten
            verarbeiten können. Um welche Daten es sich genau handelt, hängt natürlich stark von den verwendeten Tools
            ab.
            Weiter unter sehen Sie genau, welche Tools wir für unsere Website verwenden. Wir empfehlen Ihnen für nähere
            Informationen über die Datenverarbeitung auch die jeweilige Datenschutzerklärung der verwendeten Tools
            durchzulesen. Meistens erfahren Sie dort, welche Daten verarbeitet werden, ob Cookies eingesetzt werden und
            wie
            lange die Daten aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden beispielsweise auch
            Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers
            und
            Name des Browsers automatisch an die Google-Server übertragen.</p>
        <h3 class="adsimple-312757335">Dauer der Datenverarbeitung</h3>
        <p>Wie lange Daten verarbeitet werden, ist sehr individuell und hängt von den eingesetzten Webdesign-Elementen
            ab.
            Wenn Cookies beispielsweise zum Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch ein
            paar
            Jahre dauern. Machen Sie sich diesbezüglich bitte schlau. Dazu empfehlen wir Ihnen einerseits unseren
            allgemeinen Textabschnitt über Cookies sowie die Datenschutzerklärungen der eingesetzten Tools. Dort
            erfahren
            Sie in der Regel, welche Cookies genau eingesetzt werden, und welche Informationen darin gespeichert werden.
            Google-Font-Dateien werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer Website
            verbessert
            werden. Grundsätzlich werden Daten immer nur so lange aufbewahrt, wie es für die Bereitstellung des Dienstes
            nötig ist. Bei gesetzlichen Vorschreibungen können Daten auch länger gespeichert werden.</p>
        <h3 class="adsimple-312757335">Widerspruchsrecht</h3>
        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
            Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere
            Opt-Out-Funktionen. Sie können auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser
            die
            Cookies verwalten, deaktivieren oder löschen. Unter Webdesign-Elementen (meistens bei Schriftarten) gibt es
            allerdings auch Daten, die nicht ganz so einfach gelöscht werden können. Das ist dann der Fall, wenn Daten
            direkt bei einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie z. B. Google) übermittelt
            werden. Wenden Sie sich dann bitte an den Support des entsprechenden Anbieters. Im Fall von Google erreichen
            Sie
            den Support unter <a class="adsimple-312757335"
                href="https://support.google.com/?hl=de">https://support.google.com/?hl=de</a>.</p>
        <h3 class="adsimple-312757335">Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass Webdesign-Tools eingesetzt werden dürfen, ist die Rechtsgrundlage der
            entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a
            DSGVO
            (Einwilligung) die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung
            durch
            Webdesign-Tools vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes Interesse, das
            Webdesign
            auf unserer Website zu verbessern. Schließlich können wir Ihnen nur dann ein schönes und professionelles
            Webangebot liefern. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
            Interessen). Wir setzen Webdesign-Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Das
            wollen wir hier auf jeden Fall nochmals betonen.</p>
        <p>Informationen zu speziellen Webdesign-Tools erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden
            Abschnitten.</p>
        <h2 id="google-fonts-datenschutzerklaerung" class="adsimple-312757335">Google Fonts Datenschutzerklärung</h2>
        <table border="1" cellpadding="15">
            <tbody>
                <tr>
                    <td>
                        <strong class="adsimple-312757335">Google Fonts Datenschutzerklärung Zusammenfassung</strong>
                        <br />
                        &#x1f465; Betroffene: Besucher der Website<br />
                        &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse und CSS- und Schrift-Anfragen<br />
                        Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                        &#x1f4c5; Speicherdauer: Font-Dateien werden bei Google ein Jahr gespeichert<br />
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.
                        f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
            </tbody>
        </table>
        <h3 class="adsimple-312757335">Was sind Google Fonts?</h3>
        <p>Auf unserer Website verwenden wir Google Fonts. Das sind die &#8220;Google-Schriften&#8221; der Firma Google
            Inc.
            Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4,
            Irland) für alle Google-Dienste verantwortlich.</p>
        <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen.
            Weiters
            werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über
            die
            Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach
            CSS
            und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben,
            brauchen
            Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google
            übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten
            Schriftarten
            und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail
            ansehen.</p>
        <p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a
                class="adsimple-312757335"
                href="https://de.wikipedia.org/wiki/Google_LLC?tid=312757335">Google</a> Ihren
            Nutzern kostenlos zu Verfügung stellen.</p>
        <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter
            der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
        <h3 class="adsimple-312757335">Warum verwenden wir Google Fonts auf unserer Website?</h3>
        <p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem
            eigenen
            Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten.
            Alle
            Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die
            Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige
            Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche
            Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können
            zu
            Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des
            schnellen
            Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts
            unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert
            zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+
            (iPhone,
            iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und
            einheitlich wie möglich darstellen können.</p>
        <h3 class="adsimple-312757335">Welche Daten werden von Google gespeichert?</h3>
        <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
            externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
            IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und
            Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften
            nötig
            ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler
            im
            Softwarebereich.</p>
        <p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die
            gesammelten
            Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
            veröffentlicht
            Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten
            des
            eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in
            der
            BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das
            Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
        <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen
            wie Spracheinstellungen,
            IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die
            Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw.
            wird
            von Google nicht eindeutig kommuniziert.</p>
        <h3 class="adsimple-312757335">Wie lange und wo werden die Daten gespeichert?</h3>
        <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der
            EU
            angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein
            Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart
            einer
            Webseite ändern kann.</p>
        <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
            Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen,
            werden
            sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten
            Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die
            Abdeckung
            von Sprache zu erhöhen und das Design zu verbessern.</p>
        <h3 class="adsimple-312757335">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
        <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten
            werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können,
            müssen
            Sie den Google-Support auf <a class="adsimple-312757335"
                href="https://support.google.com/?hl=de&amp;tid=312757335">https://support.google.com/?hl=de&amp;tid=312757335</a>
            kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
        <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir
            können
            also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen.
            Mehr
            zu Google Fonts und weiteren Fragen finden Sie auf <a class="adsimple-312757335"
                href="https://developers.google.com/fonts/faq?tid=312757335">https://developers.google.com/fonts/faq?tid=312757335</a>.
            Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen
            über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
            Informationen
            über gespeicherten Daten zu bekommen.</p>
        <h3 class="adsimple-312757335">Rechtsgrundlage</h3>
        <p>Wenn Sie eingewilligt haben, dass Google Fonts eingesetzt werden darf, ist die Rechtsgrundlage der
            entsprechenden
            Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong class="adsimple-312757335"> Art.
                6
                Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener
            Daten, wie sie bei der Erfassung durch Google Fonts vorkommen kann, dar.</p>
        <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Font zu verwenden, um unser Online-Service
            zu
            optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong class="adsimple-312757335">Art. 6 Abs. 1
                lit. f
                DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Font gleichwohl nur ein, soweit Sie eine
            Einwilligung erteilt haben.</p>
        <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy
            Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in die USA
            geregelt wird. Mehr Informationen dazu finden Sie auf <a class="adsimple-312757335"
                href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank"
                rel="follow noopener">
                https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.
        </p>
        <p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO).
            Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte
            Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards
            entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert
            werden.
            Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei
            der
            Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in
            den
            USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss
            der
            EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a
                class="adsimple-312757335" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
        </p>
        <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die
            Standardvertragsklauseln verweisen, finden Sie unter <a class="adsimple-312757335"
                href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank"
                rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
        <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch
            auf
            <a class="adsimple-312757335"
                href="https://policies.google.com/privacy?hl=de&amp;tid=312757335">https://www.google.com/intl/de/policies/privacy/</a>
            nachlesen.
        </p>
        <h2 id="google-fonts-lokal-datenschutzerklaerung" class="adsimple-312757335">Google Fonts Lokal
            Datenschutzerklärung
        </h2>
        <p>Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das
            Unternehmen
            Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben die
            Google-Schriftarten lokal, d.h. auf unserem Webserver &#8211; nicht auf den Servern von Google &#8211;
            eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder
            Speicherung.</p>
        <h3 class="adsimple-312757335">Was sind Google Fonts?</h3>
        <p>Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis
            mit
            über 800 Schriftarten, die <a class="adsimple-312757335"
                href="https://de.wikipedia.org/wiki/Google_LLC?tid=312757335">Google</a> kostenlos bereitstellt. Mit
            Google
            Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede
            Informationsübertragung zu Google-Servern zu unterbinden, haben wir die Schriftarten auf unseren Server
            heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google Fonts
            weiter.
        </p>
        <h2 id="schlusswort" class="adsimple-312757335">Schlusswort</h2>
        <p>Herzlichen Glückwunsch! Wenn Sie diese Zeilen lesen, haben Sie sich wirklich durch unsere gesamte
            Datenschutzerklärung „gekämpft“ oder zumindest bis hier hin gescrollt. Wie Sie am Umfang unserer
            Datenschutzerklärung sehen, nehmen wir den Schutz Ihrer persönlichen Daten, alles andere als auf die leichte
            Schulter.<br />
            Uns ist es wichtig, Sie nach bestem Wissen und Gewissen über die Verarbeitung personenbezogener Daten zu
            informieren. Dabei wollen wir Ihnen aber nicht nur mitteilen, welche Daten verarbeitet werden, sondern auch
            die
            Beweggründe für die Verwendung diverser Softwareprogramme näherbringen. In der Regel klingen
            Datenschutzerklärung sehr technisch und juristisch. Da die meisten von Ihnen aber keine Webentwickler oder
            Juristen sind, wollten wir auch sprachlich einen anderen Weg gehen und den Sachverhalt in einfacher und
            klarer
            Sprache erklären. Immer ist dies natürlich aufgrund der Thematik nicht möglich. Daher werden die wichtigsten
            Begriffe am Ende der Datenschutzerklärung näher erläutert.<br />
            Bei Fragen zum Thema Datenschutz auf unserer Website zögern Sie bitte nicht, uns oder die verantwortliche
            Stelle
            zu kontaktieren. Wir wünschen Ihnen noch eine schöne Zeit und hoffen, Sie auf unserer Website bald wieder
            begrüßen zu dürfen.</p>
        <p>Alle Texte sind urheberrechtlich geschützt.</p>
        <p style="margin-top:15px">Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/"
                title="Datenschutz Generator Deutschland von AdSimple">Datenschutz Generator</a> von AdSimple</p>
    </div>
</div>