<div>
    <h2 class="font-poppins">
        {{ globalJSData.isGerman ? german.text1 : english.text1 }}
    </h2>
    <div class="img-contain">
        <div class="skill">
            <img src="assets/img/icons/html.png" class="bounce">
            <span class="font-poppins">HTML</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/css.png" class="bounce">
            <span class="font-poppins">CSS</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/javascript.png" class="bounce">
            <span class="font-poppins">JavaScript</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/angular.png" class="bounce">
            <span class="font-poppins">Angular</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/typescript.png" class="bounce">
            <span class="font-poppins">TypeScript</span>
        </div>
    </div>
    <h2 class="font-poppins">
        {{ globalJSData.isGerman ? german.text2 : english.text2 }}
    </h2>
    <div class="img-contain">
        <div class="skill">
            <img src="assets/img/icons/bootstrap.png" class="bounce">
            <span class="font-poppins">Bootstrep</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/materialdesign.png" class="bounce">
            <span class="font-poppins">Material<br>Design</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/git.png" class="bounce">
            <span class="font-poppins">GIT</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/api.png" class="bounce">
            <span class="font-poppins">Rest-API</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/firebase.png" class="bounce">
            <span class="font-poppins">Firebase</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/gimp.png" class="bounce">
            <span class="font-poppins">Gimp</span>
        </div>
        <div class="skill">
            <img src="assets/img/icons/chatgpt.png" class="bounce">
            <span class="font-poppins">ChatGPT</span>
        </div>
    </div>
</div>