import { Component } from '@angular/core';

@Component({
  selector: 'app-about-me-photo',
  standalone: true,
  imports: [],
  templateUrl: './about-me-photo.component.html',
  styleUrl: './about-me-photo.component.scss'
})
export class AboutMePhotoComponent {

}
